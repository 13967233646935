import React, { memo, useEffect } from 'react'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { ICategory } from '@interfaces'
import {
  RootState,
  addSubCategoryAction,
  updateSubCategoryAction,
  useAppDispatch,
} from '@redux'
import { message } from 'antd'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button, Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { customSlugify } from 'src/utils/customSlugify'
import styled from 'styled-components'

interface IConfirmDeleteModalProps {
  open: boolean
  isLoading: boolean
  title: string
  onClose: () => void
  item?: ICategory
  onSuccess: () => void
}

const EditSubCategoryModal = (props: IConfirmDeleteModalProps) => {
  const { open, isLoading, title, onClose, item, onSuccess } = props
  const dispatch = useAppDispatch()

  const { categories } = useSelector((state: RootState) => state.categories)

  const schemaCategoryTitle = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(50, 'Category title may not be greater than 50 characters'),
    nameInDutch: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(50, 'Category title may not be greater than 50 characters'),
    slug: yup
      .string()
      .trim()
      .required(t('error:slug_required')?.replace('Slug', 'Keyword'))
      .max(50, t('error:slug_length_error')?.replace('Slug', 'Keyword')),
    categoryId: yup.number().required(t('error:field_required')),
  })

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<{
    name: string
    nameInDutch: string
    slug: string
    categoryId: number
  }>({
    resolver: yupResolver(schemaCategoryTitle),
    defaultValues: {
      name: item?.name || '',
      nameInDutch: item?.nameInDutch || '',
      slug: item?.slug || '',
    },
  })

  useEffect(() => {
    clearErrors()
    reset()
    if (item) {
      setValue('name', item.name)
      setValue('nameInDutch', item.nameInDutch)
      setValue('slug', item.slug)
      setValue('categoryId', item?.parentCategoryId)
    }
  }, [item, setValue, open])
  useEffect(() => {
    if (categories.length > 0 && !item) {
      setValue('categoryId', categories[0].id)
    }
  }, [open, categories])
  const updateCategory = async (data: {
    name: string
    nameInDutch: string
    slug: string
    categoryId: number
  }) => {
    if (!item) {
      return
    }
    try {
      const response = await dispatch(
        updateSubCategoryAction({
          id: item.id,
          slug: data.slug || '',
          name: data.name || '',
          nameInDutch: data.nameInDutch || '',
          categoryId: data.categoryId,
        })
      ).unwrap()
      message.success({
        content: 'Success',
      })
      onSuccess()
    } catch (error: any) {
      message.error({
        content: error?.message,
      })
    }
  }
  const addNewCategory = async (data: {
    name: string
    nameInDutch: string
    slug: string
    categoryId: number
  }) => {
    try {
      const response = await dispatch(
        addSubCategoryAction({
          name: data.name || '',
          nameInDutch: data.nameInDutch || '',
          slug: data.slug,
          categoryId: data.categoryId || categories[0].id,
        })
      ).unwrap()

      message.success({
        content: 'Add sub category successfully!',
      })
      reset()
      onSuccess()
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }
  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {!!item ? title : 'Add new sub category'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6 w-[60dvw]">
        <FormStyled>
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Name"
                  name="name"
                  alignment="col"
                  required
                  value={value}
                  onChange={(event) => {
                    onChange(event?.target?.value)
                    setValue(
                      'slug',
                      customSlugify(event?.target?.value?.trim())
                    )
                  }}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                />
              )
            }}
          />

          <Controller
            name="nameInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Name in Netherlands"
                  name="nameInDutch"
                  alignment="col"
                  required
                  value={value}
                  onChange={onChange}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                />
              )
            }}
          />

          <Controller
            name={'slug'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Keyword"
                  name="slug"
                  alignment="col"
                  required
                  onChange={onChange}
                  value={value}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                />
              )
            }}
          />
          {categories?.length > 0 && (
            <Controller
              name={'categoryId'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Category"
                    name="categoryId"
                    alignment="col"
                    type="select"
                    options={categories?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    required
                    onChangeSelect={onChange}
                    value={value}
                    style={{ marginTop: 10 }}
                    errors={error?.message}
                  />
                )
              }}
            />
          )}
          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="large"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="submit__btn login-btn"
              loading={isLoading}
              onClick={
                item
                  ? handleSubmit(updateCategory)
                  : handleSubmit(addNewCategory)
              }
            >
              Save
            </Button>
          </div>
        </FormStyled>
      </div>
    </AppModal>
  )
}

export default memo(EditSubCategoryModal)

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Input__label {
    margin-bottom: 0 !important;
  }
`
